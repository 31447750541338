import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Config } from '../config/config'

import Layout from '../layouts'

import '../scss/list.scss'

import image from '../assets/images/news-image-min.jpg'
import defaultNewsImage from '../assets/images/news-default-image.jpg'

const DraftNewsPage = ({data}) => {
  const { allMarkdownRemark } = data

  const news = allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.type === 'article' && edge.node.frontmatter.is_published == false)

  return (
    <Layout>
      <div className="list-page news">

        <Helmet>
          <title>DigiWells – News</title>
          <meta property="og:title" content="DigiWells – News" />
          <meta name="description" content="Latest news from DigiWells." />
          <meta property="og:description" content="Latest news from DigiWells." />
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
        </Helmet>

        <div className="container">
          <div className="container-7">
            <h1 className="page-title">Draft News</h1>

            <div className="list">
              {
                news.map(function(edge, index) {
                  const item = edge.node.frontmatter
                  const slug = edge.node.fields.slug

                  return (
                    <a className="list-item article" href={slug} key={index}>
                      <div className="list-item-image" style={{ backgroundImage: `url(${item.list_image ? item.list_image : defaultNewsImage})` }}>

                      </div>
                      <div className="list-item-text">
                        <h3 className="list-item-title small">
                          {item.title}
                        </h3>
                        <div className="list-item-description">
                          {item.short_description}
                          <br/>
                          {item.date}
                        </div>
                      </div>
                      <div className="list-item-icon">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="30" cy="30" r="30" fill="#222742" fillOpacity="0.1"/>
                          <path d="M34.0003 20.6666L32.1203 22.5466L38.227 28.6666H16.667V31.3333H38.227L32.107 37.4533L34.0003 39.3333L43.3337 30L34.0003 20.6666Z" fill="#222742"/>
                        </svg>
                      </div>
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}

// Query
export const query = graphql`
  query DraftNewsPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            short_description
            list_image
            is_published
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default DraftNewsPage
